import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu(props) {
    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <Link to={{
                pathname: `/info`
            }}>
                INFO
            </Link>
            <Link to={{
                pathname: `/contact`
            }}>
                CONTACT
            </Link>
        </div>
    );
}

export default TopMenu;
