import React from 'react';
import "./Loader.scss"

const Loader = () => {
    return (
        <div className="loader-wrapper">
            <div className="loader loader-2">
                <svg className="loader-star">
                    <polygon
                        points="29.8 0.3 22.8 21.8 0 21.8 18.5 35.2 11.5 56.7 29.8 43.4 48.2 56.7 41.2 35.1 59.6 21.8 36.8 21.8 "
                        fill="#e5062f"/>
                </svg>
                <div className="loader-circles"></div>
            </div>
        </div>);
};

export default Loader;
