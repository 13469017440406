import React, {useState} from 'react'
import './Search.scss';

export default function Search({keyword,setKeyword}) {

    return (
        <form className="search-form">
            <input 
                className="search-input"
                key="random1"
                value={keyword}
                placeholder={"Search"}
                onChange={(e) => setKeyword(e.target.value)}
            />
        </form>
    )
}